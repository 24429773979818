@font-face {
    font-family: 'Lexend';
    font-display: swap;
    src: url('/fonts/Lexend.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    font-display: block;
    src: url('/fonts/Inter.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Rajdhani-Light';
    src: url('/fonts/Rajdhani-Light.ttf') format('truetype');
    font-weight: 300;
  }
  
  @font-face {
    font-family: 'Rajdhani-Regular';
    src: url('/fonts/Rajdhani-Regular.ttf') format('truetype');
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'Rajdhani-Medium';
    src: url('/fonts/Rajdhani-Medium.ttf') format('truetype');
    font-weight: 500;
  }
  
  @font-face {
    font-family: 'Rajdhani-SemiBold';
    src: url('/fonts/Rajdhani-SemiBold.ttf') format('truetype');
    font-weight: 600;
  }
  
  @font-face {
    font-family: 'Rajdhani-Bold';
    src: url('/fonts/Rajdhani-Bold.ttf') format('truetype');
    font-weight: 700;
  }
  